// website preview
.website-preview-bg::after {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(9, 34, 53, 0.48);
  display: block;
  position: absolute;
  z-index: 1;
}

// Active & Running Btn spin border effect
.spin-gradient-border {
  background-size: 300% 300%;
  animation: gradient 2s linear infinite;

  &.success-border {
    background-image: linear-gradient(
      60deg,
      #00ca9f,
      #00a5eb,
      #00ca9f,
      #00a5eb
    );
  }

  &.warning-border {
    background-image: linear-gradient(
      60deg,
      #f24822,
      #efc700,
      #f24822,
      #efc700
    );
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
