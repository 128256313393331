* {
  scrollbar-width: 0;
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.scrollbar-hide {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 0px solid transparent;
  }
}
