body {
  font-family: 'Roboto', sans-serif;
}

.text-xxs-l {
  font-size: 20px;
  line-height: 20px;
  transform: scale(0.5);
  transform-origin: left;
}

.text-xxs-c {
  font-size: 20px;
  line-height: 20px;
  transform: scale(0.5);
  transform-origin: center;
}

.text-xxs-r {
  font-size: 20px;
  line-height: 20px;
  transform: scale(0.5);
  transform-origin: right;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rdrStaticRangeSelected,
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview,
.rdrStartEdge,
.rdrEndEdge,
.rdrInRange {
  color: rgb(0, 165, 235) !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: rgb(0, 165, 235) !important;
}

.rdrDateDisplayItemActive {
  border-color: rgb(0, 165, 235) !important;
}

// account-setting 边框渐变+圆角  https://juejin.cn/post/6844903972281516045
.account-setting {
  position: relative;
  height: 35px;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 8px;
    background-image: linear-gradient(
      92.06deg,
      #00a5eb 2.01%,
      #01dbac 100%,
      #00ca9f 100%
    );
    z-index: 2;
  }
  &::after {
    content: '';
    position: absolute;
    background-color: #00151f;
    border-radius: 8px;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    z-index: 3;
  }
  & > div {
    position: absolute;
    z-index: 5;
    padding: 9px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &:hover {
    &::after {
      display: none;
    }
  }
}

// 自定义选中样式的class
.option-box-class {
  position: relative;
  border-radius: 8px;
  border: 2px solid #e1e5e9;
  color: #092235;
  padding: 16px 24px;
  cursor: pointer;
  &.selected {
    border: 2px solid #00a5eb;
    &::after {
      content: ' ';
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background-color: #00a5eb;
      border-radius: 0px 0px 0px 8px;
    }
    &::before {
      content: ' ';
      position: absolute;
      right: 4px;
      top: 4px;
      z-index: 9;
      width: 12px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.203 2.925c.396.4.396 1.053 0 1.453L5.557 9.075c-.395.4-1.042.4-1.437 0L1.797 6.727a1.038 1.038 0 0 1 0-1.454 1.01 1.01 0 0 1 1.437 0l1.604 1.622 3.928-3.97c.396-.4 1.042-.4 1.437 0z' fill='%23fff'/%3E%3C/svg%3E");
    }
  }
  &.no-selected {
    border: 2px solid #e1e5e9;
  }
  &:hover {
    border: 2px solid #00a5eb;
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    &::after {
      content: ' ';
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      z-index: 99;
      width: auto;
      height: auto;
      background-color: rgba(255, 255, 255, 0.84);
    }
  }
}

.location-selector:hover {
  .location-name {
    color: #fff;
  }
}
