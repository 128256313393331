@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

$transparent: transparent;
$current: currentColor;
$white: #ffffff;
$slate: #f5f6fa; // page bg/field bg
$zinc: #e1e5e9; // border
$stone: #e6e6e6; // disabled
$ink: #092235; // dark text
$lead: rgba(9, 34, 53, 0.64); // midium text
$silver: rgba(9, 34, 53, 0.32); // light text / placeholder
// $light: {
//   8: rgba(255, 255, 255, 0.08),
//   16: rgba(255, 255, 255, 0.16),
//   32: rgba(255, 255, 255, 0.32),
//   64: rgba(255, 255, 255, 0.64),
//   100: rgba(255, 255, 255)
// },
$blue: #00a5eb;
$green: #00ca9f;
$yellow: #efc700;
$red: #ef4a41;
