.ru-form-search-select {
  opacity: 1;

  #search-select-input {
    opacity: 1 !important;
    position: absolute;
    left: 0;
    right: 0;
  }
}
