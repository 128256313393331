.terms {
  p {
    margin-bottom: 1rem;
  }
  strong {
    color: #092235;
  }
  h1 {
    color: #092235;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
  }
  h2 {
    color: #092235;
    font-size: 24px;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
  }
  h4 {
    color: #092235;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  a {
    color: #00a5eb;
    text-decoration: none;
  }
}
