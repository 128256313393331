.bar {
  position: absolute;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: #00a5eb;
  transform-style: flat;
  animation: bar_animate ease 1.2s infinite;
}

.bar:nth-child(2) {
  margin-left: 12px;
  animation-delay: 0.2s;
}

.bar:nth-child(3) {
  margin-left: 24px;
  animation-delay: 0.3s;
}

.bar:nth-child(4) {
  margin-left: 36px;
  animation-delay: 0.4s;
}

.bar:nth-child(5) {
  margin-left: 48px;
  animation-delay: 0.5s;
}

@keyframes bar_animate {
  0%, 100% {
    height: 10px;
  }
  50% {
    transform: translateY(-12px);
    height: 40px;
  }
}