input:disabled {
  // -webkit-text-fill-color: $ink;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  border: 1px solid $slate;
  -webkit-text-fill-color: $ink;
  -webkit-box-shadow: 0 0 0px 1000px $slate inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $stone;
  -webkit-text-fill-color: $ink;
  -webkit-box-shadow: 0 0 0px 1000px $slate inset;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: #d8dcdf;
  font-size: 16px;
  line-height: 1.4;
}
